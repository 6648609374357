import moment from "moment";
import React, { useEffect, useState } from "react";
import { PersonLinesFill, TruckFlatbed } from "react-bootstrap-icons";
import { CookiesProvider, useCookies } from "react-cookie";
import { Field, Form } from "react-final-form";
// import redux
import { useDispatch, useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import {
  setAuth,
  setDriver,
  setJobs,
  setLoginFailureReason,
  setModalToggle,
  setSearchFailureReason,
  setSelectedContainer,
  setSelectedJob,
  setSelectedSite,
  setSelectedVehicle,
  setVehicles
} from ".././features/allocateme/allocateme";
import AllotracRect from "../assets/images/logos/allotracRect.png";

const AllocateMeLoginForm = ({}) => {
  const [cookies, setCookie] = useCookies(["allocateme"]);
  const backendProxyURL = useSelector(
    state => state.allocateme.backendProxyURL
  );
  const loginFailureReason = useSelector(
    state => state.allocateme.loginFailureReason
  );

  const dispatch = useDispatch();
  const onSubmit = e => {
    console.log(e);

    dispatch(setSelectedSite(e.site));
    const loginUrl = backendProxyURL + "/api/allotracLogin";
    dispatch(setLoginFailureReason(false));
    // post login request to oauth2 server
    fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        grant_type: "password",
        client_id: "K4CV5vrLBB2KS3Vy",
        username: e.license,
        password: e.password,
        site: e.site.toLowerCase()
      })
    })
      .then(response => response.json())
      .then(resp => {
        if (resp.success == false) {
          dispatch(setLoginFailureReason(resp.data));
        } else {
          console.log(resp.data.auth);

          var tokenExpires = moment()
            .add(resp.data.auth.expires_in, "seconds")
            .unix();

          //copy resp.data.auth to new object to modify
          var auth = { ...resp.data.auth };
          auth.expires_on = tokenExpires;

          dispatch(setAuth(auth));
          setCookie("auth", auth, { path: "/" });
          let driver = { ...resp.data.driver };
          driver.license = e.license;
          dispatch(setDriver(driver));

          setCookie("driver", driver, { path: "/" });
          dispatch(setVehicles(resp.data.vehicles));
          setCookie("vehicles", resp.data.vehicles, { path: "/" });
          dispatch(setSelectedSite(e.site));
          setCookie("site", e.site, { path: "/" });
        }
      });
  };

  return (
    <Form
      onSubmit={e => onSubmit(e)}
      validate={values => {
        const errors = {};

        if (!values.license) {
          errors.license = "";
        }

        if (!values.password) {
          errors.password = "";
        }
        if (!values.site) {
          errors.site = "";
        }

        return errors;
      }}
      render={({ handleSubmit, values, submitting, validating, valid }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup style={{ fontSize: 20, fontWeight: "bold" }}>
            <Label for="license">License Number</Label>
            <Field name="license">
              {({ input, meta }) => (
                <div>
                  <Input {...input} type="text" placeholder="license number" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
          </FormGroup>

          <FormGroup style={{ fontSize: 20, fontWeight: "bold" }}>
            <Label for="password">Password</Label>
            <Field name="password">
              {({ input, meta }) => (
                <div>
                  <Input {...input} type="password" placeholder="password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
          </FormGroup>
          <FormGroup style={{ fontSize: 20, fontWeight: "bold" }}>
            <Label for="site">Site</Label>
            <Field name="site">
              {({ input, meta }) => (
                <div>
                  <Input {...input} type="text" placeholder="bobstrucks" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
          </FormGroup>
          {loginFailureReason && (
            <Alert color="danger">{loginFailureReason}</Alert>
          )}
          <hr></hr>
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              textAlign: "center"
            }}
          >
            <Button
              style={{ width: "200px", fontSize: 20, fontWeight: "bold" }}
              type="submit"
              color="primary"
              disabled={!valid}
            >
              Login
            </Button>
          </Row>
          <Row></Row>
        </form>
      )}
    />
  );
};
const JobCard = ({ job }) => {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.allocateme.modalToggle);
  const toggleModal = (job, e) => {
    dispatch(setSelectedJob(job));
    console.log(
      "Setting modal toggle to: " + !modal + " for job: " + job.jobId
    );
    dispatch(setModalToggle(!modal));
  };
  console.log("Rendering job");
  console.log(job);
  const selectedContainer = useSelector(
    state => state.allocateme.selectedContainer
  );

  function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  return (
    <Card
      key={"JobCard-" + job.jobId}
      className="border border-primary"
      style={{ width: "100%", marginTop: "10px" }}
    >
      <CardBody onClick={e => toggleModal(job, e)}>
        <Row style={{ width: "auto" }}>
          <Col sm="6" md="6" lg="6" xl="6">
            <p>
              <span style={{ fontWeight: "bold" }}>Container: </span>{" "}
              <span> {selectedContainer}</span>
            </p>
          </Col>
          <Col sm="6" md="6" lg="6" xl="6">
            <p>
              <span style={{ fontWeight: "bold" }}>Job ID: </span>{" "}
              <span> {job.jobId}</span>
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="6" md="6" lg="6" xl="6">
            <span style={{ fontWeight: "bold" }}>Items: </span>{" "}
          </Col>
          <Col sm="6" md="6" lg="6" xl="6">
            {job.items.map(item => (
              <p key={item.itemId}>
                <span>
                  {item.itemQuantity} * {truncate(item.itemName, 23)}
                </span>
              </p>
            ))}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="6" md="6" lg="6" xl="6">
            <p>
              <span style={{ fontWeight: "bold" }}>Supplier: </span>
              <span> {truncate(job.supplierCompany, 16)}</span>
            </p>
          </Col>
          <Col sm="6" md="6" lg="6" xl="6">
            <p>
              <span style={{ fontWeight: "bold" }}>Address: </span>
              <span> {truncate(job.supplierAddress, 18)}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="6" lg="6" xl="6">
            <p>
              <span style={{ fontWeight: "bold" }}>Customer: </span>
              <span> {truncate(job.customerCompany, 13)}</span>
            </p>
          </Col>
          <Col sm="6" md="6" lg="6" xl="6">
            <span style={{ fontWeight: "bold" }}>Address: </span>
            <span> {truncate(job.customerAddress, 16)}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const AllocateMeJobContainer = ({}) => {
  const jobs = useSelector(state => state.allocateme.jobs);
  // state for localJobs
  const [localJobs, setLocalJobs] = useState([]);
  // refresh when jobs get updated
  useEffect(() => {
    setLocalJobs(jobs);
  }, [jobs]);

  return (
    <div>
      {localJobs.map(job => (
        <JobCard key={"JobCardInstance" + job.jobId} job={job} />
      ))}
    </div>
  );
};

const AllocateMeJobConfigModal = ({ onClose }) => {
  const backendProxyURL = useSelector(
    state => state.allocateme.backendProxyURL
  );

  const selectedJob = useSelector(state => state.allocateme.selectedJob);

  const selectedSite = useSelector(state => state.allocateme.selectedSite);

  const allocateJob = () => {
    let url = backendProxyURL + "/api/allotracAllocate";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        site: selectedSite,
        jobId: selectedJob.jobId,
        truckId: selectedVehicle.id
      })
    })
      .then(response => response.json())
      .then(resp => {
        if (resp.success != true) {
          alert("Job Not Allocated!! Reason: " + resp.data);
          dispatch(setModalToggle(!modal));
        } else {
          alert("Job allocated!");
          dispatch(setModalToggle(!modal));
        }
      });
  };

  const dispatch = useDispatch();
  const modal = useSelector(state => state.allocateme.modalToggle);
  const selectedVehicle = useSelector(
    state => state.allocateme.selectedVehicle
  );
  const selectedContainer = useSelector(
    state => state.allocateme.selectedContainer
  );

  const toggleModal = (job, e) => {
    dispatch(setModalToggle(!modal));
  };
  console.log();
  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Allocate this job?</ModalHeader>
      <ModalBody>
        <b>Job:</b> {selectedJob.jobId}
        <br />
        <b>Container:</b> {selectedContainer}
        <br />
        <b>Truck:</b> {selectedVehicle.identifier}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={allocateJob}>
          Allocate
        </Button>{" "}
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const AllocateMeSearchContainer = ({}) => {
  const selectedSite = useSelector(state => state.allocateme.selectedSite);
  const selectedContainer = useSelector(
    state => state.allocateme.selectedContainer
  );
  const searchFailureReason = useSelector(
    state => state.allocateme.searchFailureReason
  );
  const backendProxyURL = useSelector(
    state => state.allocateme.backendProxyURL
  );
  const dispatch = useDispatch();

  const onSubmit = e => {
    console.log(backendProxyURL);
    dispatch(setSearchFailureReason(false));
    let containerNumber = e.containerNumber.toUpperCase();
    dispatch(setSelectedContainer(containerNumber.toUpperCase()));
    let url =
      backendProxyURL +
      "/api/allotracSearchContainer?containerNumber=" +
      containerNumber.toUpperCase() +
      "&site=" +
      selectedSite;
    fetch(url, {
      method: "GET"
    })
      .then(response => response.json())
      .then(resp => {
        if (resp.data.length == 0) {
          dispatch(setSearchFailureReason("No such container found!"));
        } else {
          console.log(resp);
          dispatch(setJobs(resp.data));
        }
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const errors = {};

        if (!values.containerNumber) {
          errors.containerNumber = false;
        }

        return errors;
      }}
      render={({ handleSubmit, values, submitting, validating, valid }) => (
        <form>
          <AllocateMeJobConfigModal />
          <Row style={{}}>
            <Col sm="8">
              <FormGroup style={{ fontSize: 20, fontWeight: "bold" }}>
                <Field name="containerNumber">
                  {({ input, meta }) => (
                    <div>
                      <Input
                        {...input}
                        type="text"
                        placeholder="Enter Container Number..."
                        invalid={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </FormGroup>
            </Col>

            <Col
              className="m-auto"
              style={{
                textAlign: "center",
                alignContent: "center",
                justifyContent: "center"
              }}
            >
              <Button
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  cursor: "pointer"
                }}
                type="submit"
                color="primary"
                onTouchStart={e => handleSubmit(e)}
                onClick={e => handleSubmit(e)}
                disabled={!valid}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Row
            className="m-auto"
            style={{
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center"
            }}
          >
            <Col
              className="m-auto"
              style={{
                textAlign: "center",
                alignContent: "center",
                justifyContent: "center"
              }}
            >
              {searchFailureReason && (
                <Alert
                  style={{ marginTop: "30px", width: "100%" }}
                  color="danger"
                >
                  {searchFailureReason}
                </Alert>
              )}
            </Col>
          </Row>
        </form>
      )}
    >
      <FormGroup>
        <Input type="text" name="search" id="search" placeholder="Search" />
      </FormGroup>
    </Form>
  );
};

const VehicleCard = ({ vehicle }) => {
  console.log(vehicle);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["allocateme"]);

  const setVehicleFunction = vehicle => {
    dispatch(setSelectedVehicle(vehicle));
    setCookie("vehicle", vehicle, { path: "/" });
  };

  return (
    <Card
      key={"VehicleCard-" + vehicle.id}
      className="border border-primary"
      style={{ marginTop: "10px", width: "auto", minWidth: 0 }}
    >
      <CardBody onClick={e => setVehicleFunction(vehicle)}>
        <Row style={{ width: "auto", minWidth: 0 }}>
          <Col
            sm="1"
            style={{
              width: "auto",
              justifyContent: "center",
              textAlign: "center",
              alignContent: "center"
            }}
            className="m-auto"
          >
            <TruckFlatbed size="40" />
          </Col>
          <Col className="m-auto">
            <Row style={{ width: "auto", minWidth: 0 }} className="m-auto">
              <Col sm="4" className="m-auto">
                <span style={{ fontWeight: "bold" }}>Identifier: </span>{" "}
              </Col>
              <Col sm="4" className="m-auto">
                <span> {vehicle.identifier}</span>
              </Col>
            </Row>
            <hr />
            <Row style={{ width: "auto" }} className="m-auto">
              <Col sm="4" className="m-auto">
                <span style={{ fontWeight: "bold" }}>Licence Plate: </span>{" "}
              </Col>

              <Col sm="4" className="m-auto">
                <span> {vehicle.licencePlate}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const VehiclesCard = ({}) => {
  const dispatch = useDispatch();
  const vehicles = useSelector(state => state.allocateme.vehicles);

  return (
    <Card style={{ borderRadius: "20px" }}>
      <CardBody>
        <CardTitle
          style={{
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 40 }}>Select Vehicle</div>
          <hr></hr>
        </CardTitle>
        <div>
          {vehicles.map(vehicle => {
            // copy vehicle to avoid mutating the original
            const vehicleCopy = { ...vehicle };

            return (
              <VehicleCard
                key={"JobCardInstance" + vehicleCopy.id}
                vehicle={vehicleCopy}
              />
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

const DriverCard = ({}) => {
  const driver = useSelector(state => state.allocateme.driver);
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(
    state => state.allocateme.selectedVehicle
  );
  console.log(driver);
  const [cookies, setCookie, removeCookie] = useCookies(["allocateme"]);
  const logout = () => {
    dispatch(setDriver(null));
    dispatch(setVehicles(null));
    dispatch(setSelectedVehicle(null));
    dispatch(setJobs(null));
    removeCookie("driver");
    removeCookie("vehicles");
    removeCookie("vehicle");
    removeCookie("auth");
    window.location.reload(false);
  };
  return (
    <Card style={{ borderRadius: "20px" }}>
      <CardBody>
        <Row>
          <Col className="m-auto" xs="2">
            <PersonLinesFill onClick={() => logout()} size={40} />
          </Col>
          <Col>
            <Row>Name: {driver.firstNames + " " + driver.lastName}</Row>
            <Row>License: {driver.license}</Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="m-auto" xs="2">
            {selectedVehicle && <TruckFlatbed size={40} />}
          </Col>
          <Col>
            {selectedVehicle && (
              <div>
                <Row>Identifier: {selectedVehicle.identifier}</Row>
                <Row>License: {selectedVehicle.licencePlate}</Row>
              </div>
            )}
          </Col>
          {/* {selectedVehicle && (
            <div>
              
                <TruckFlatbed size="20" />
              
          
                <Col>Identifier: {selectedVehicle.identifier}</Col>
                <Col>License: {selectedVehicle.licencePlate}</Col>
            
            </div>
          )} */}
        </Row>
      </CardBody>
    </Card>
  );
};

const ContainerSearchCard = ({}) => {
  return (
    <Card style={{ borderRadius: "20px" }}>
      <CardBody>
        <CardTitle
          style={{
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <hr />
        </CardTitle>
        <Col>
          <AllocateMeSearchContainer />
          <hr></hr>
          <AllocateMeJobContainer />
        </Col>
      </CardBody>
    </Card>
  );
};
const HeaderCard = ({}) => {
  const auth = useSelector(state => state.allocateme.auth);
  return (
    <Card style={{ borderRadius: "20px" }}>
      <CardBody>
        <hr style={{ padding: 0, margin: 0 }} />

        <CardTitle
          style={{
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: 0,
            margin: 0
          }}
        >
          <div>
            <img
              style={{ padding: 0, margin: 0, height: "100px" }}
              src={AllotracRect}
              alt="Allotrac"
            />
          </div>
          <hr style={{ padding: 0, margin: 0 }} />
        </CardTitle>
      </CardBody>
    </Card>
  );
};

const LoginCard = ({}) => {
  const auth = useSelector(state => state.allocateme.auth);
  return (
    <Card style={{ borderRadius: "20px" }}>
      <CardBody>
        <CardTitle
          style={{
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 38 }}>
            AllocateMe - Login
          </div>
          <hr />
        </CardTitle>
        <Col>{auth.access_token === null ? <AllocateMeLoginForm /> : null}</Col>
      </CardBody>
    </Card>
  );
};

const AllocateMe = token => {
  useEffect(() => {
    console.log("useEffect");
    document.body.style.backgroundColor = "#00263A";
  }, []);

  const auth = useSelector(state => state.allocateme.auth);
  const selectedVehicle = useSelector(
    state => state.allocateme.selectedVehicle
  );
  const [cookies, setCookie, removeCookie] = useCookies(["allocateme"]);
  const dispatch = useDispatch();
  console.log("COOKIES");
  console.log(cookies);
  const logout = () => {
    dispatch(setDriver(null));
    dispatch(setVehicles(null));
    dispatch(setSelectedVehicle(null));
    dispatch(setJobs(null));
    removeCookie("driver");
    removeCookie("vehicles");
    removeCookie("vehicle");
    removeCookie("auth");
    window.location.reload(false);
  };
  useEffect(() => {
    if (cookies.auth !== undefined) {
      if (cookies.auth.expires_on < moment().unix()) {
        logout();
      }

      dispatch(setAuth(cookies.auth));
      dispatch(setDriver(cookies.driver));
    }
    if (cookies.vehicle !== undefined) {
      dispatch(setSelectedVehicle(cookies.vehicle));
    }
    if (cookies.vehicles !== undefined) {
      dispatch(setVehicles(cookies.vehicles));
    }
    if (cookies.site !== undefined) {
      dispatch(setSelectedSite(cookies.site));
    }
  });
  return (
    <CookiesProvider>
      <div style={{ height: "100vh", overflow: "scroll" }}>
        <Row
          style={{
            paddingTop: "30px",
            paddingLeft: "10px",
            paddingRight: "10px"
          }}
        >
          <Col></Col>
          <Col xs="12" sm="12" md="10" lg="4" xl="4">
            <HeaderCard />
            {/* {(!auth.access_token || !selectedVehicle) && <VehiclesCard />} */}
            {auth.access_token && (
              <div>
                <DriverCard />
                {!selectedVehicle && <VehiclesCard />}
                {selectedVehicle && <ContainerSearchCard />}
              </div>
            )}
            {!auth.access_token && <LoginCard />}
          </Col>
          <Col></Col>
        </Row>
      </div>
    </CookiesProvider>
  );
};

export default AllocateMe;
