import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const JobImportLogs = lazy(() => import("../views/JobImportLogs"));
const DOpt = lazy(() => import("../views/DOpt"));
const AllocateMe = lazy(() => import("../views/AllocateMe"));
const DispatchLogin = lazy(() => import("../views/DispatchLogin"));
const DispatchApp = lazy(() => import("../views/DispatchApp"));
/*****Routes******/

const ThemeRoutes = token => [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/starter" /> },
      { path: "/starter", exact: true, element: <Starter token={token} /> },
      {
        path: "/jobimportlogs",
        exact: true,
        element: <JobImportLogs token={token} />
      },
      { path: "/dopt", exact: true, element: <DOpt token={token} /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
      { path: "/allocateme", exact: true, element: <AllocateMe /> },
      {
        path: "/dispatchVehicles",
        exact: true,
        element: <DispatchApp page="vehicles" />
      },
      { path: "/dispatchLogin", exact: true, element: <DispatchLogin /> },
      {
        path: "/dispatchDrivers",
        exact: true,
        element: <DispatchApp page="drivers" />
      },
      {
        path: "/dispatchSubcontractors",
        exact: true,
        element: <DispatchApp page="subcontractors" />
      },
      {
        path: "/dispatchContacts",
        exact: true,
        element: <DispatchApp page="contacts" />
      },
      {
        path: "/dispatchLocations",
        exact: true,
        element: <DispatchApp page="locations" />
      },
      {
        path: "/dispatchProducts",
        exact: true,
        element: <DispatchApp page="products" />
      },
      {
        path: "/dispatchProjects",
        exact: true,
        element: <DispatchApp page="projects" />
      },
      {
        path: "/dispatchJobProof",
        exact: true,
        element: <DispatchApp page="jobproof" />
      },
      {
        path: "/dispatchSubcontacts",
        exact: true,
        element: <DispatchApp page="subcontacts" />
      },
      {
        path: "/dispatchJobs",
        exact: true,
        element: <DispatchApp page="jobs" />
      }
    ]
  }
];

export default ThemeRoutes;
