import { configureStore } from "@reduxjs/toolkit";
import allocatemeReducer from "./features/allocateme/allocateme";
import allotracDispatchReducer from "./features/allotracDispatch/allotracDispatch";
import counterReducer from "./features/counter/counterSlice";
import mapReducer from "./features/map/mapSlice";
import solutionsReducer from "./features/solverData/solverDataSlice";
// import { devToolsEnhancer } from 'redux-devtools-extension';
import { dispatchApi } from "./features/allotracDispatch/allotracDispatchService";

export const store = configureStore({
  reducer: {
    [dispatchApi.reducerPath]: dispatchApi.reducer,
    counter: counterReducer,
    solutions: solutionsReducer,
    map: mapReducer,
    allocateme: allocatemeReducer,
    allotracDispatch: allotracDispatchReducer
  },
  enhancers: [],
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(dispatchApi.middleware)
});
// ,devToolsEnhancer())

export default store;
