import { createSlice } from "@reduxjs/toolkit";

export const allocateMeSlice = createSlice({
  name: "allocateme",
  initialState: {
    selectedJob: { jobId: null },
    selectedVehicle: null,
    selectedSite: null,
    selectedContainer: null,
    auth: {
      access_token: null,
      expires_on: null
    },
    driver: {
      firstNames: null,
      id: null,
      lastName: null,
      licenseNumber: null
    },
    loginFailureReason: false,
    searchFailureReason: false,
    vehicles: [],
    vehicle: {
      identifier: null,
      licencePlate: null,
      id: null
    },
    jobs: [],

    modalToggle: false,

    backendProxyURL:
      process.env.NODE_ENV === "development"
        ? "http://localhost:5002"
        : "https://allotrac-log-viewer-api.allotrac.com.au"
  },
  reducers: {
    setSelectedJob: (state, job) => {
      state.selectedJob = job.payload;
    },

    setAuth: (state, auth) => {
      state.auth = auth.payload;
    },
    setJobs: (state, jobs) => {
      state.jobs = jobs.payload;
    },

    setDriver: (state, driver) => {
      state.driver = driver.payload;
    },

    setVehicles: (state, vehicles) => {
      state.vehicles = vehicles.payload;
    },

    setVehicle: (state, vehicle) => {
      state.vehicle = vehicle.payload;
    },

    setModalToggle: (state, toggle) => {
      state.modalToggle = toggle.payload;
    },
    setSelectedVehicle: (state, vehicle) => {
      console.log(vehicle);
      state.selectedVehicle = vehicle.payload;
    },
    setSelectedSite: (state, site) => {
      state.selectedSite = site.payload;
    },
    setSelectedContainer: (state, container) => {
      state.selectedContainer = container.payload;
    },
    setLoginFailureReason: (state, reason) => {
      state.loginFailureReason = reason.payload;
    },
    setSearchFailureReason: (state, reason) => {
      state.searchFailureReason = reason.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setSelectedJob,
  setAuth,
  setDriver,
  setVehicles,
  setVehicle,
  setModalToggle,
  setSelectedVehicle,
  setSelectedSite,
  setJobs,
  setSelectedContainer,
  setLoginFailureReason,
  setSearchFailureReason
} = allocateMeSlice.actions;

export default allocateMeSlice.reducer;
