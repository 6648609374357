import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    selectedLocation: {},
    htmlData: ""
  },
  reducers: {
    update: (state, location) => {
      state.selectedLocation = location;
    }
  }
});

// Action creators are generated for each case reducer function
export const { update } = mapSlice.actions;

export default mapSlice.reducer;
