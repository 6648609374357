import { createSlice } from "@reduxjs/toolkit";
import {
  useGetContactQuery,
  useGetSubContactQuery
} from "../../features/allotracDispatch/allotracDispatchService";

export const allotracDispatch = createSlice({
  name: "allotracDispatch",
  initialState: {
    dispatchAuth: {
      access_token: null,
      // expires_on: null,
      loginFailureReason: null
    },
    subdomain: "",
    vehicles: {}, // { vehicleId: : vehicle, vehicleId2:vehicle  } }
    selectedVehicleId: null,
    drivers: {}, // { driverId: : driver, driverId2:driver  } }
    selectedDriverId: null,
    subcontractors: [],
    selectedSubcontractorId: null,
    contacts: {}, // { contactId: : contact, contactId2:contact  } }
    selectedContactId: null,
    subContacts: {}, // { subContactId: : subContact, subContactId2:subContact  } }
    selectedSubContactId: null,
    selectedSubContact: {},
    locations: {}, // { locationId: : location, locationId2:location  } }
    selectedLocationId: null,
    products: {}, // { productId: : product, productId2:product  } }
    selectedProductId: null,
    projects: {}, // { projectId: : project, projectId2:project  } }
    selectedProjectId: null,
    jobProofs: {}, // { jobProofId: : jobProof, jobProofId2:jobProof  } }
    selectedJobProofId: null,
    jobs: {}, // { jobId: : job, jobId2:job  } }
    selectedJobId: null,
    contactIdToLoad: 0,

    vehicleLicenceTypeMap: {
      "LIC-Allotrac": "Full",
      "LIC-Job": "Per Job",
      "LIC-AddressLookup": "Address Lookup",
      "LIC-Count": "Count",
      "LIC-Crane": "Crane",
      "LIC-Doc": "Document",
      "LIC-Track": "Tracking",
      "LIC-Tonne": "Per Tonne",
      "LIC-Con-Allotrac": "Con-Allotrac",
      "LIC-Con-Track": "Con-Tracking",
      "LIC-SUB-Job": "Subcontractor per Job",
      "LIC-SUB-Tonne": "Subcontractor per Tonne"
    },
    vehicleClassTypeMap: {
      "1": "Car",
      "2": "Light Rigid",
      "3": "Medium Rigid",
      "4": "Heavy Rigid",
      "5": "Heavy Combination",
      "6": "Multi Combination"
    },
    subbieTypeMap: {
      "0": "Private",
      "1": "Public"
    },
    allocatorTypeMap: {
      "0": "Allocator",
      "1": "Self"
    }
  },
  reducers: {
    setDispatchAuth: (state, dispatchAuth) => {
      state.dispatchAuth = dispatchAuth.payload;
      console.log(state.dispatchAuth);
    },

    setLoginFailureReason: (state, reason) => {
      state.auth.loginFailureReason = reason.payload;
    },
    setSubdomain: (state, subdomain) => {
      state.subdomain = subdomain.payload;
    },

    setVehicles: (state, vehicles) => {
      state.vehicles = vehicles.payload;
    },
    getVehicles: (state, vehicles) => {
      state.vehicles = vehicles.payload;
    },
    setSelectedVehicleId: (state, selectedVehicleId) => {
      state.selectedVehicleId = selectedVehicleId.payload;
    },
    setDrivers: (state, drivers) => {
      state.drivers = drivers.payload;
    },
    setSelectedDriverId: (state, selectedDriverId) => {
      state.selectedDriverId = selectedDriverId.payload;
    },
    setSubcontractors: (state, subcontractors) => {
      console.log("Adding subcontractors to state!");
      console.log(subcontractors.payload);
      let stateCopy = state.subcontractors;
      subcontractors.payload.forEach(subContractor => {
        stateCopy.forEach(existingSubContractor => {
          if (
            existingSubContractor.SubContractorId ===
            subContractor.SubContractorId
          ) {
            existingSubContractor = subContractor;
          } else {
            stateCopy.push(subContractor);
          }
        });
        state.subcontractors = stateCopy;
      });
      console.log(state.subcontractors);
    },

    setSelectedSubcontractorId: (state, selectedSubcontractorId) => {
      state.selectedSubcontractorId = selectedSubcontractorId.payload;
    },
    setContacts: (state, contacts) => {
      state.contacts = contacts.payload;
    },
    setSelectedContactId: (state, selectedContactId) => {
      state.selectedContactId = selectedContactId.payload;
    },
    setSubContacts: (state, subContacts) => {
      console.log("Adding subContacts to state!");
      console.log(subContacts.payload);
      let stateCopy = state.subContacts;
      subContacts.payload.forEach(subContact => {
        stateCopy.forEach(existingsubContact => {
          if (existingsubContact.SubContactId === subContact.SubContactId) {
            existingsubContact = subContact;
          } else {
            stateCopy.push(subContact);
          }
        });
      });

      state.subContacts = stateCopy;
    },
    setSelectedSubContactId: (state, selectedSubContactId) => {
      const { data } = useGetSubContactQuery({
        SubContactId: selectedSubContactId
      });
      state.selectedSubContact = data;
      state.selectedSubContactId = selectedSubContactId.payload;
    },
    setLocations: (state, locations) => {
      state.locations = locations.payload;
    },
    setSelectedLocationId: (state, selectedLocationId) => {
      state.selectedLocationId = selectedLocationId.payload;
    },
    setProducts: (state, products) => {
      state.products = products.payload;
    },
    setSelectedProductId: (state, selectedProductId) => {
      state.selectedProductId = selectedProductId.payload;
    },
    setProjects: (state, projects) => {
      state.projects = projects.payload;
    },
    setSelectedProjectId: (state, selectedProjectId) => {
      state.selectedProjectId = selectedProjectId.payload;
    },
    setJobProofs: (state, jobProofs) => {
      state.jobProofs = jobProofs.payload;
    },
    setSelectedJobProofId: (state, selectedJobProofId) => {
      state.selectedJobProofId = selectedJobProofId.payload;
    },
    setJobs: (state, jobs) => {
      state.jobs = jobs.payload;
    },
    setSelectedJobId: (state, selectedJobId) => {
      state.selectedJobId = selectedJobId.payload;
    },
    setSelectedJobProof: (state, jobProof) => {
      state.selectedJobProofId = jobProof.payload.id;
      state.jobProofs[state.selectedJobProofId] = jobProof.payload;
    },
    setContactIdToLoad: (state, contactIdToLoad) => {
      state.contactIdToLoad = contactIdToLoad.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDispatchAuth,
  setLoginFailureReason,
  setSubdomain,
  setVehicles,
  setSelectedVehicleId,
  setDrivers,
  setSelectedDriverId,
  setSubcontractors,
  setSelectedSubcontractorId,
  setContacts,
  setSelectedContactId,
  setContactIdToLoad,
  setSubContacts,
  setSelectedSubContactId,
  setLocations,
  setSelectedLocationId,
  setProducts,
  setSelectedProductId,
  setProjects,
  setSelectedProjectId,
  setJobProofs,
  setSelectedJobProofId,
  setJobs,
  setSelectedJobId,
  setSelectedJob,
  setSelectedJobProof,
  selectedSubContact
} = allotracDispatch.actions;

export default allotracDispatch.reducer;
