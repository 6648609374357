import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const solverDataSlice = createSlice({
  name: "solutions",
  initialState: {
    solutions: [],
    htmlData: "",
    selectedDate: { payload: new moment().add(1, "d").format("YYYY-MM-DD") },
    selectedSite: "sandbox",
    jobData: [],
    loaderState: false,
    costMatrixProgress: { current: 0, total: 0, percent: 0 },
    socketWaitingForAnswer: false,
    solverService: "jsprit",
    iterations: 200,
    solverFunction: null,
    selectedTruck: null,
    currentIteration: 0,
    percentComplete: 0,
    progressBarText: "Waiting for first iteration...",
    selectedTab: 0,
    currentSolverTask: "",
    showMap: false,
    currentSolutionGeoJSON: {
      type: "FeatureCollection",
      features: []
    },
    formFields: {},
    configureFields: {
      jsprit: {
        iterations: 200,
        iterationReportRate: 5,
        title: "Jsprit Configuration"
      },
      graphhopper: {
        url: "http://localhost:8080/route",
        apiKey: "AAA-BBB-123",
        title: "GraphHopper Configuration"
      }
    }
  },

  reducers: {
    add: (state, msg) => {
      state.solutions = msg;
    },

    setOptDate: (state, date) => {
      state.selectedDate = date;
    },
    setOptSite: (state, site) => {
      state.selectedSite = site.payload.value;
      console.log("SELECTEDSITE");
      console.log(state.selectedSite);
    },
    addJobData: (state, jobData) => {
      // console.log("ADDJOBDATA");
      // console.log(jobData);
      state.jobData = jobData.payload;
      // console.log(state.jobData);
    },
    setLoaderState: (state, loaderState) => {
      // console.log("Set Loader State");
      // console.log(loaderState);
      state.loaderState = loaderState.payload;
      // console.log(state.loaderState);
    },

    setSocketWaitingForAnswer: (state, socketWaitingForAnswer) => {
      // console.log("Set Socket Waiting For Answer");
      // console.log(socketWaitingForAnswer);
      state.socketWaitingForAnswer = socketWaitingForAnswer.payload;
      // console.log(state.socketWaitingForAnswer);
    },

    setSolverService: (state, solver) => {
      // console.log("Set Solver");
      // console.log(solver);
      state.solver = solver;
      // console.log(state.solver);
    },

    setIterations: (state, iterations) => {
      // console.log("Set Iterations");
      // console.log(iterations);
      state.iterations = iterations.payload;
      // console.log(state.iterations);
    },

    setCurrentIteration: (state, currentIteration) => {
      // console.log("Set Current Iteration");
      // console.log(currentIteration);
      state.currentIteration = Math.round(currentIteration.payload);
      // console.log(state.currentIteration);
    },
    setPercentComplete: (state, percentComplete) => {
      // console.log("Set Percent Complete");
      // console.log(percentComplete);
      // state.percentComplete = Math.round((percentComplete.payload.iteration / state.iterations) * 100)
      state.percentComplete = Math.round(
        (percentComplete.payload.iteration / state.iterations) * 100
      );

      state.progressBarText = `${percentComplete.payload.iteration} / ${state.iterations}`;
      // console.log(percentComplete);
      // console.log(state.percentComplete);
    },
    setProgressBarText: (state, progressBarText) => {
      // console.log("Set Progress Bar Text");
      // console.log(progressBarText);
      // console.log(state.progressBarText);
    },
    setCurrentSolutionGeoJSON: (state, currentSolutionGeoJSON) => {
      // console.log("Set Current Solution GeoJSON");
      // console.log(currentSolutionGeoJSON);
      state.currentSolutionGeoJSON = currentSolutionGeoJSON.payload;
      // console.log(state.currentSolutionGeoJSON);
    },
    setFormFields: (state, formFields) => {
      // console.log("Set Form Fields");
      // console.log(formFields.payload);
      state.formFields = formFields.payload;
      // console.log(state.formFields);
    },
    setJSpritConfigureFields: (state, jSpritConfigureFields) => {
      // console.log("Set JSprit Configure Fields");
      // console.log(jSpritConfigureFields);
      state.jSpritConfigureFields = jSpritConfigureFields.payload;
      // console.log(state.jSpritConfigureFields);
    },

    setGraphHopperConfigureFields: (state, graphHopperConfigureFields) => {
      // console.log("Set GraphHopper Configure Fields");
      // console.log(graphHopperConfigureFields);
      state.graphHopperConfigureFields = graphHopperConfigureFields.payload;
      // console.log(state.graphHopperConfigureFields);
    },

    deleteVehicle: (state, id) => {
      // console.log("Delete Vehicle");
      // console.log(id);
      state.jobData.vehicles.splice(id, 1);
      // console.log(state.jobData.vehicles);
    },
    deleteJob: (state, id) => {
      // console.log("Delete Job");
      // console.log(id);
      state.jobData.jobs.splice(id, 1);
      // console.log(state.jobData.jobs);
    },

    updateVehicle: (state, updateObj) => {
      // console.log("Update Vehicle");
      let id = updateObj.payload.id;
      let key = updateObj.payload.key;
      let value = updateObj.payload.value;
      // console.log("ID: " + id + " key: " + key + " Value: " + value);
      // find the vehicle with the id key that matches the id of the update
      let vehicle = state.jobData.vehicles.find(vehicle => vehicle.id === id);

      // if key is capacity
      if (key === "capacity") {
        // set value to integer
        vehicle.capacity[1] = parseInt(value);
      } else if (key === "startTime") {
        vehicle.time_window[0] = moment(value).unix();
      } else if (key === "endTime") {
        vehicle.time_window[1] = moment(value).unix();
      } else if (key === "startLocationLat") {
        vehicle.start[0] = parseFloat(value);
      } else if (key === "startLocationLon") {
        vehicle.start[1] = parseFloat(value);
      } else if (key === "endLocationLat") {
        vehicle.end[0] = parseFloat(value);
      } else if (key === "endLocationLon") {
        vehicle.end[1] = parseFloat(value);
      }
    },
    updateJob: (state, updateObj) => {
      // console.log("Update Job");
      let id = updateObj.payload.id;
      let key = updateObj.payload.key;
      let value = updateObj.payload.value;
      // console.log("ID: " + id + " key: " + key + " Value: " + value);
      let jobData = state.jobData.jobs.find(job => job.JobID === id);
      // console.log(jobData)
    },
    setSelectedTab: (state, selectedTab) => {
      // console.log("Get Selected Tab");
      // console.log(selectedTab);
      state.selectedTab = selectedTab.payload;
      // console.log(state.selectedTab);
    },

    setSolverFunction: (state, solverFunction) => {
      // console.log("Set Solver Function");
      // console.log(solverFunction);
      state.solverFunction = solverFunction.payload;
      // console.log(state.solverFunction);
    },
    setSelectedTruck: (state, selectedTruck) => {
      console.log("SET SELECTED TRUCK");
      console.log(selectedTruck);
      state.selectedTruck = selectedTruck.payload;
      // console.log(state.selectedTruck.id);
    },
    setCostMatrixProgress: (state, costMatrixProgress) => {
      let percentage = Math.round(
        (costMatrixProgress.payload.current /
          costMatrixProgress.payload.total) *
          100
      );
      state.costMatrixProgress.percent = percentage;
      state.costMatrixProgress.current = costMatrixProgress.payload.current;
      state.costMatrixProgress.total = costMatrixProgress.payload.total;
    },
    setCurrentSolverTask: (state, currentSolverTask) => {
      state.currentSolverTask = currentSolverTask.payload;
    },
    setShowMap(state, showMap) {
      console.log("Set Show Map");
      console.log(showMap);
      state.showMap = showMap.payload;
      console.log(state.showMap);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  add,
  clearData,
  incrementByAmount,
  setOptDate,
  setOptSite,
  addJobData,
  setLoaderState,
  setSocketWaitingForAnswer,
  setSolverService,
  setIterations,
  setCurrentIteration,
  setPercentComplete,
  setProgressBarText,
  setCurrentSolutionGeoJSON,
  setJSpritConfiguration,
  setFormFields,
  setJSpritConfigureFields,
  setGraphHopperConfigureFields,
  deleteVehicle,
  updateVehicle,
  updateJob,
  deleteJob,
  setSelectedTab,
  setSolverFunction,
  setSelectedTruck,
  setCostMatrixProgress,
  setCurrentSolverTask,
  setShowMap
} = solverDataSlice.actions;

export default solverDataSlice.reducer;
